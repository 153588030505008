/** @jsx jsx */
import  {  useEffect } from "react"

import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Flex } from "@theme-ui/components"

import { findBySlug, renderLineBreak } from "../helpers"

import Carousel from "../components/carousel"
import Container from "../components/container"
import Column from "../components/column"
import CTA from "../components/cta"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import Row from "../components/row"
import TabbedCards from "../components/tabbed-cards"
import Text from "../components/text"
import ThreeRolloverCardPattern from "../components/three-rollover-card-pattern"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"

const AdvantagesPage = ({ data }) => {
  paramsCheck();
  
  useEffect(() => {
    document.head.innerHTML += '<meta name="robots" content="noindex">'
  }, []);
  
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-hero",
    })
    const heroHeights = [200, 300]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
      ></Hero>
    )
  }
  const renderAdvantagesBladePattern = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-pattern-advantages",
    })
    const ScrollTo = name => {
      const elem = document.getElementById(name)
      window.scrollTo({ left: 0, top: elem.offsetTop + 30, behavior: "smooth" })
    }
    return (
      <Container
        customStyle={{
          py: theme => [
            0,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
          pb: [40, 70, null],
        }}
      >
        <Row>
          {advantagesBladePatternData.contentCards.map(cardData => (
            <Column size={[12, 12, 3]} key={kebabCase(cardData.title)}>
              <button
                sx={{
                  border: 0,
                  background: "transparent",
                  cursor: "pointer",
                  p: 0,
                  ":hover *": {
                    color: theme => theme.colors.primary,
                  },
                }}
                onClick={() => ScrollTo(kebabCase(cardData.slug))}
              >
                <Flex
                  sx={{
                    justifyContent: ["unset", "unset", "center"],
                    textAlign: ["left", "left", "center"],
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                    position: "relative",
                  }}
                >
                  <Flex
                    sx={{
                      flexDirection: "column",
                      paddingLeft: [5, 5, 0],
                      maxWidth: ["85%", "100%", 234],
                    }}
                  >
                    <Text type="h3">{cardData.title}</Text>

                    {cardData.description &&
                      documentToReactComponents(cardData.description.json, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <Text
                              customStyle={{
                                display: ["none", "none", "block"],
                                mt: theme => [0, 0, theme.spacing.vertical.sm],
                              }}
                            >
                              {children}
                            </Text>
                          ),
                        },
                      })}
                    <div
                      sx={{
                        height: 32,
                        width: [32, 32, "auto"],
                        textAlign: "center",
                        position: ["absolute", "absolute", "relative"],
                        left: [-10, -10, 0],
                        top: ["0", "0", "unset"],
                        mt: theme => [0, 0, theme.spacing.vertical.md],
                      }}
                    >
                      <img
                        sx={{ height: "100%" }}
                        src={cardData.mobileImage.file.url}
                        alt={cardData.mobileImage.title}
                      />
                    </div>
                  </Flex>
                </Flex>
              </button>
            </Column>
          ))}
        </Row>
      </Container>
    )
  }
  const renderWordClassDesignBladePattern = () => {
    const worldClassDesignBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-world-class-design-gallery",
    })
    const worldClassdesignCTAsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "world-class-design-ctas",
    })
    const renderWorldClassDesignGallery = () => (
      <Carousel type="quaternary">
        {worldClassDesignBladePatternData.contentCards.map(contentCard => (
          <div key={kebabCase(contentCard.title)}>
            <img
              alt=""
              src={`${contentCard.desktopImage.file.url}`}
              sx={{ borderRadius: "5px" }}
            />
          </div>
        ))}
      </Carousel>
    )
    const renderWorldClassDesignCTAs = () => {
      return (
        <Row customStyle={{ p: [0, 0, 6] }}>
          {worldClassdesignCTAsBladePatternData.contentCards.map(
            contentCard => (
              <Column
                customStyle={{
                  mt: theme => [50, 50, 0],
                }}
                size={[12, 12, 4]}
                key={kebabCase(contentCard.title)}
              >
                <Text type="h3">{contentCard.title}</Text>

                {contentCard.description &&
                  documentToReactComponents(contentCard.description.json, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Text
                          customStyle={{
                            mt: theme => [20, theme.spacing.vertical.sm],
                          }}
                        >
                          {children}
                        </Text>
                      ),
                    },
                  })}
                <div
                  sx={{
                    mt: theme => [20, 20, theme.spacing.vertical.md],
                  }}
                >
                  <CTA
                    link={get(
                      contentCard,
                      "ctaDestination.ctaDestination",
                      "/"
                    )}
                    type="secondary"
                    linkType="internal"
                  >
                    {contentCard.ctaTitle}
                  </CTA>
                </div>
              </Column>
            )
          )}
        </Row>
      )
    }

    return (
      <div
        id="page-blade-pattern-world-class-design-gallery"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        <Container>
          <Row>
            <Column>
              <Text
                type="h2"
                customStyle={{ fontSize: [36, 40], mb: [30, 30, null] }}
              >
                {renderLineBreak(worldClassDesignBladePatternData.title)}
              </Text>
            </Column>
          </Row>
          <Row customStyle={{ p: [0, 0, 6] }}>
            <Column>{renderWorldClassDesignGallery()}</Column>
          </Row>
          {renderWorldClassDesignCTAs()}
        </Container>
      </div>
    )
  }
  const renderSustainableBladePattern = () => {
    const sustainableBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-sustainable-healthy-construction",
    })
    return (
      <div
        id="page-blade-pattern-sustainable-healthy-construction"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <TabbedCards
          type="horizontal"
          data={sustainableBladePatternData}
          titleStyle={{ fontSize: [36, 40] }}
          imageStyle={{ borderRadius: "5px" }}
        />
      </div>
    )
  }
  const renderEachProjectIsUniqueBladePattern = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })

    return (
      <ProjectUnique data={eachProjectIsUniqueBladePatternData}></ProjectUnique>
    )
  }
  const renderFasterLowerCostBladePattern = () => {
    const fasterLowerCostBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-faster-lower-cost",
    })

    return (
      <div id="page-blade-pattern-faster-lower-cost">
        <ThreeRolloverCardPattern
          data={fasterLowerCostBladePatternData}
          titleStyle={{ fontSize: [36, 40] }}
        />
      </div>
    )
  }
  const renderPatentedBuildSystemBladePattern = () => {
    const renderPatentedBuildSystemData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-pantented-building-system",
    })

    return (
      <div id="page-blade-pattern-pantented-building-system">
        <ThreeRolloverCardPattern
          data={renderPatentedBuildSystemData}
          titleStyle={{ fontSize: [36, 40] }}
        />
      </div>
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderAdvantagesBladePattern()}
      {renderWordClassDesignBladePattern()}
      {renderFasterLowerCostBladePattern()}
      {renderSustainableBladePattern()}
      {renderPatentedBuildSystemBladePattern()}
      {renderEachProjectIsUniqueBladePattern()}
      {renderQuote()}
    </Page>
  )
}

export default AdvantagesPage

export const query = graphql`
  query advantagesPageQuery {
    contentfulPage(slug: { eq: "advantages" }) {
      ...PageData
      ...BladePatternData
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "advantages-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
