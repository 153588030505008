/** @jsx jsx */
import { jsx } from "theme-ui"

import { Tab as ReachTab, TabList as ReachTabList } from "@reach/tabs"
import Text from "./text"

const Tab = props => (
  <div
    sx={{
      mr: [5, 5, 3],
      px: [0, 0, 15],
      ":last-child": { mr: 0 },
      ...(props.isSelected && {
        borderBottomColor: "primary",
        borderBottomStyle: "solid",
        borderBottomWidth: [2, 2, 3],
        pb: ["2px", "2px", "8px"],
      }),
      ...props.containerStyle,
    }}
  >
    <ReachTab
      {...props}
      sx={{
        background: "none",
        border: "none",
        cursor: "pointer",
        display: "block",
        p: 0,
        whiteSpace: ["nowrap", "nowrap", "normal"],
        ...props.tabStyle,
      }}
    >
      {props.text ? (
        <Text customStyle={{ fontWeight: "heading", fontSize: [2, 2, 4] }}>
          {props.text}
        </Text>
      ) : (
        props.children
      )}
    </ReachTab>
  </div>
)

const TabList = props => (
  <ReachTabList
    sx={{
      display: "flex",
      justifyContent: ["flex-start", "flex-start", "center"],
      maxWidth: ["92%", "92%", "none"],
      mb: theme => [
        theme.spacing.vertical.sm,
        theme.spacing.vertical.sm,
        theme.spacing.vertical.md,
      ],
      overflowX: ["auto", "auto", "auto", "visible"],
      // "::after": {
      // color: "primary",
      // content: '">>"',
      // display: ["block", "block", "none"],
      // fontFamily: "body",
      // fontSize: 1,
      // marginTop: "-3px",
      // position: "absolute",
      // right: 20,
      // },
      ...props.sx,
    }}
    {...props}
  />
)

export { Tab, TabList }
