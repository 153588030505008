/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { TabPanel, TabPanels, Tabs } from "@reach/tabs"

import Column from "./column"
import Container from "./container"
import CTA from "./cta"
import Row from "./row"
import { Tab, TabList } from "./custom-tabs"
import Text from "./text"
import RichText from "./rich-text"

const TabbedCards = ({
  data,
  type,
  titleStyle,
  textStyle,
  containerStyle,
  imageStyle,
}) => {
  const [currentTab, setCurrentTab] = React.useState(0)
  const renderContentCardDescriptionList = description => {
    return (
      <div
        sx={{
          display: ["block", "block", "flex"],
          maxWidth: 690,
          mt: theme => [2, theme.spacing.vertical.md],
          mx: "auto",
          flexDirection: "column",
          ...textStyle,
        }}
      >
        <RichText data={description} />
      </div>
    )
  }
  const print = index => {
    if (data.contentCards.length && index > data.contentCards.length - 1) {
      document.getElementById("tabscroll").scrollLeft = 0
      setCurrentTab(0)
    } else {
      document.getElementById("tabscroll").scrollLeft = index * 100
      setCurrentTab(index)
    }
  }

  const renderTitle = () => (
    <Row>
      <Column>
        <Text
          customStyle={{
            ...(type !== "horizontal" ? { textAlign: "center" } : null),
            mb: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
            ...titleStyle,
          }}
          type="h2"
        >
          {data.title}
        </Text>
      </Column>
    </Row>
  )
  return (
    <Container
      customStyle={{
        py: theme => [theme.spacing.vertical.md, theme.spacing.vertical.lg],
        ...containerStyle,
      }}
    >
      <Column customStyle={{ maxWidth: "100%" }}>
        {renderTitle()}
        <Row
          customStyle={{
            flexDirection: ["column-reverse", "column-reverse", "unset"],
          }}
        >
          <Column
            size={type !== "horizontal" ? [12] : [12, 12, 4]}
            customStyle={{ maxWidth: "100%" }}
          >
            <Tabs index={currentTab} onChange={print}>
              <div
                sx={{
                  display: "flex",
                  justifyContent: ["flex-start", "flex-start", "center"],
                  pr: 20,
                }}
              >
                <TabList
                  id="tabscroll"
                  {...(type === "horizontal"
                    ? {
                        sx: {
                          justifyContent: [
                            "flex-start",
                            "flex-start",
                            "flex-start",
                          ],
                          mb: [30, null],
                          mr: [0, 0, 0],
                          maxWidth: ["100%", "100%", "100%"],
                          "::after": {
                            display: "none",
                          },
                        },
                      }
                    : { sx: { mb: [4, null] } })}
                >
                  {data.contentCards.map(contentCard => (
                    <Tab
                      key={contentCard.id}
                      text={contentCard.title}
                      {...(type === "horizontal" && {
                        containerStyle: {
                          mx: [10, 10, 10],
                          pr: [0, 0, 0],
                          pl: [0, 0, 0],
                          ":last-child": { mr: 0 },
                          ":first-child": { ml: 0 },
                          "@media screen and (min-width: 1100px)": {
                            mx: 15,
                          },
                          "@media screen and (min-width: 1300px)": {
                            mx: 25,
                          },
                        },
                      })}
                    />
                  ))}
                  {data.ctaDestination && data.ctaTitle && (
                    <Tab sx={{ display: ["none", "none", "block"] }} disabled>
                      <CTA
                        type="secondary"
                        link={data.ctaDestination}
                        linkType="internal"
                      >
                        {data.ctaTitle}
                      </CTA>
                    </Tab>
                  )}
                </TabList>
                <div
                  sx={{
                    color: "primary",
                    content: '">>"',
                    display: ["block", "block", "none"],
                    fontFamily: "body",
                    fontSize: 1,
                    marginTop: "-3px",
                    position: "absolute",
                    right: 20,
                  }}
                  onClick={() => print(currentTab + 1)}
                >
                  
                </div>
              </div>

              <TabPanels>
                {data.contentCards.map(contentCard => (
                  <TabPanel key={contentCard.id}>
                    {contentCard.desktopImage && type !== "horizontal" && (
                      <div
                        sx={{
                          maxHeight: 350,
                          maxWidth: 980,
                          mx: "auto",
                        }}
                      >
                        <Img
                          fluid={contentCard.desktopImage.fluid}
                          alt={contentCard.desktopImage.title}
                          sx={{ ...imageStyle }}
                        />
                      </div>
                    )}
                    {contentCard.description &&
                      renderContentCardDescriptionList(contentCard.description)}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
            {data.ctaDestination && data.ctaTitle && (
              <div
                sx={{
                  display: ["block", "block", "none"],
                  mt: theme => theme.spacing.vertical.sm,
                }}
              >
                <CTA
                  type="secondary"
                  link={data.ctaDestination}
                  linkType="internal"
                >
                  {data.ctaTitle}
                </CTA>
              </div>
            )}
          </Column>
          {type === "horizontal" && (
            <Column size={[12, 12, 8]}>
              <img
                src={data.contentCards[currentTab].desktopImage.file.url}
                alt={data.contentCards[currentTab].desktopImage.title}
                sx={{ width: "100%", mb: [3, 3, 0, 0], ...imageStyle }}
              />
            </Column>
          )}
        </Row>
      </Column>
    </Container>
  )
}

export default TabbedCards
