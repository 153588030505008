/** @jsx jsx */
import { Flex, jsx } from "theme-ui"
import kebabCase from "lodash/kebabCase"

import { getBackgroundList, renderLineBreak } from "../helpers"

import Container from "../components/container"
import Column from "../components/column"
import RollOverCard from "../components/rollover-card"
import Row from "../components/row"
import Text from "../components/text"
import CTA from "./cta"

const ThreeRollOverCardPattern = ({ data, titleStyle }) => {
  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row customStyle={{ px: 10 }}>
          <Column
            size={[12, 12, 6]}
            // customStyle={{
            //   mb: theme => [
            //     theme.spacing.vertical.sm,
            //     theme.spacing.vertical.sm,
            //     0,
            //   ],
            //   ":last-child": { mb: 0 },
            // }}
          >
            <Text
              type="h2"
              customStyle={{
                mb: 20,
                ...titleStyle,
              }}
            >
              {renderLineBreak(data.title)}
            </Text>
          </Column>
          <Column
            size={[12, 12, 6]}
            customStyle={{
              mt: theme => [theme.spacing.vertical.sm],
            }}
          >
            <Flex
              sx={{
                justifyContent: ["flex-start", "flex-start", "flex-end"],
              }}
            >
              <div>
                {data.ctaTitle ? (
                  <CTA
                    link={data.ctaDestination || "/"}
                    type={"secondary"}
                    linkType="internal"
                  >
                    {data.ctaTitle}
                  </CTA>
                ) : null}
              </div>
            </Flex>
          </Column>
        </Row>
        <Row
          customStyle={{
            pt: theme => [20],
          }}
        >
          {data.contentCards.map((contentCard, idx) => (
            <Column
              size={[12, 12, 4]}
              customStyle={{
                mt: [6, null],
                ":first-child": {
                  mt: [0, 20, 64],
                },
              }}
              key={`${kebabCase(contentCard.title)}-${idx}`}
            >
              <RollOverCard
                background={getBackgroundList(contentCard)}
                title={contentCard.title}
                description={contentCard.description}
              />
            </Column>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ThreeRollOverCardPattern
